<template>
  <edit-template :confirm-loading="confirmLoading" :data-loading="dataLoading" @confirm="submit">
    <el-form ref="form" :model="form" :rules="rules" label-width="140rem">
      <el-form-item label="账号" prop="username">
        <el-input :value="form.username" placeholder="请输入入职联系方式手机号" type="number" @input="onChangeMobile"/>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入" type="text"/>
      </el-form-item>
      <!--      <el-form-item prop="id_card" label="身份证号">-->
      <!--        <el-input v-model="form.id_card" maxlength="18" placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item prop="mailbox" label="邮箱">-->
      <!--        <el-input v-model="form.mailbox" placeholder="请输入"/>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item prop="nation" label="民族">-->
      <!--        <el-select-->
      <!--            v-model="form.nation"-->
      <!--            filterable-->
      <!--            remote-->
      <!--            reserve-keyword-->
      <!--            placeholder="请输入关键词"-->
      <!--            :remote-method="querySearch"-->
      <!--            :loading="loading">-->
      <!--          <el-option-->
      <!--              v-for="item in nationOptions"-->
      <!--              :key="item.id"-->
      <!--              :label="item.name"-->
      <!--              :value="item.id">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item v-if="roleInfo.is_admin" label="是否为集团员工" prop="is_group_member">
        <el-radio-group v-model="form.is_group_member" @input="getSchool($event,true)">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属校区" prop="school">
        <el-select v-model="form.school" :disabled="!roleInfo.is_admin || (roleInfo.is_admin && form.is_group_member == null)">
          <el-option v-for="item in schoolList" :key="item.id" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否为部门负责人" prop="department_head">
        <el-radio v-model="form.department_head" :label="1">是</el-radio>
        <el-radio v-model="form.department_head" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="岗位类型" prop="role_type">
        <el-select v-model="form.role_type" @change="onRoleTypeChange">
          <el-option v-for="item in roleTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择岗位" prop="role_id">
        <el-select v-model="form.role_id" :disabled="!form.role_type">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="教学学科" prop="subject">
        <el-cascader v-model="form.subject" :options="subjects"></el-cascader>
      </el-form-item>

    </el-form>
  </edit-template>
</template>

<script>
import {getSubject} from './api';

export default {
  _config: {"route": {"path": "add", "meta": {"title": "新增"}}},
  data() {
    return {
      roleInfo: {},
      loading: false,
      dataLoading: false,
      confirmLoading: false,
      form: {
        username: null, // 手机号
        name: null, // 姓名
        id_card: null,// 身份证号
        nation: null, // 民族
        school: null, // 校区
        is_group_member: null, // 是否为集团员工
        department_head: null, // 是否为部门负责人
        role_id: null,
        role_type: null,
        subject: null
      },
      nationOptions: [],
      subjects: [], // 教学学科列表
      schoolList: [],
      // 岗位列表
      roleTypeList: [],
      // 岗位列表
      roleList: [],
      rules: {
        username: [{required: true, message: '请输入手机号', trigger: 'blur'}, {min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur"}],
        name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        id_card: [{pattern: /^((\d{17})|(\d{14}))[0-9X]$/, message: "请输入正确的身份证号", trigger: "blur"}],
        school: [{required: true, message: '请选择校区', trigger: 'change'}],
        nation: [{required: true, message: '请输入民族', trigger: 'change'}],
        is_group_member: [{required: true, message: '请选择是否为集团员工', trigger: 'change'}],
        department_head: [{required: true, message: '请选择是否为部门负责人', trigger: 'change'}],
        role_id: [{required: true, message: '请选择选择岗位', trigger: 'change'}],
        role_type: [{required: true, message: '请选择岗位类型', trigger: 'change'}],
        mailbox: [{required: true, message: '请输入邮箱地址', trigger: 'change'}],
        subject: [{required: true, message: '请选择教学学科', trigger: 'change'}],
      },
      keyword: '',
    };
  },
  mounted() {
    this.getData().finally(() => this.dataLoading = false);
  },
  methods: {
    // 限制手机号长度
    onChangeMobile(val) {
      if (val.length <= 11)
        this.form.username = val;
    },
    getSchool(is_group_member, isLoading = false) {
      this.form.school = null;
      if (isLoading) this.dataLoading = true;
      // 获取校区
      return this.$_axios2.get("api/permission/school-list", {
        logic: 1,
        params: {
          is_group_member
        }
      }).then(res => {
        this.schoolList = res.data;
      }).finally(res => {
        if (isLoading) this.dataLoading = false;
      })
    },
    // 获取数据
    async getData() {
      this.dataLoading = true;
      // 获取岗位类型
      await this.$_axios2.get("api/permission/role-type", {logic: 1}).then(res => {
        this.roleTypeList = res.data;
      }).catch(err => {
        console.log(err.msg || err.message);
      })
      // 获取角色类型
      await this.$utils.loginApi.getRole().then(res => {
        this.roleInfo = res.data;
      }).catch(err => {
        console.log(err.msg || err.message);
      })
      // 获取教学学科
      await getSubject().then(res => {
        this.subjects = JSON.parse(
            JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/subject_name/g, 'label').replace(/child/g, 'children'),
        );
      });

      // 如果为非管理员 获取校区
      let {is_admin} = this.roleInfo;
      if (!is_admin) {
        await this.getSchool(0);
        this.form.school = this.schoolList[0].id;
      }
    },
    // 岗位类型改变时触发
    onRoleTypeChange(e) {
      this.dataLoading = true;
      this.form.role_id = null;
      this.$_axios2.get("api/permission/role-type-list", {
        params: {
          level: e
        },
        logic: 1
      }).then(res => {
        this.roleList = res.data;
      }).finally(() => this.dataLoading = false)
    },
    // 搜索民族
    querySearch(str) {
      this.loading = true;
      this.$_axios2.get("api/common/nation", {
        params: {
          keyword: str
        },
        logic: 1
      }).then(res => {
        this.nationOptions = res.data;
      }).finally(() => this.loading = false)
    },
    // 提交
    submit() {
      this.$refs.form.validate(res => {
        if (res) {
          this.confirmLoading = true;
          let params = Object.assign({}, this.form);
          params.is_group_member = params.is_group_member || 0;
          delete params.role_type;
          this.$_axios2.post("api/account/add-account", params, {logic: 1}).then(res => {
            this.$message.success("添加成功");
            this.$store.commit("setPage", 1);
            this.$router.back();
          }).finally(() => this.confirmLoading = false)
        }
      });
    },
  },

  created() {
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  min-width: 100rem;
  margin: 10rem;
}

::v-deep .el-input--small .el-input__inner {
  width: 300rem;
  height: 40rem;
  color: #999;
}

::v-deep .el-checkbox-group {
  max-height: 400rem !important;
  overflow: scroll;
}
</style>
